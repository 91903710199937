import { Button, Upload } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { ApplicationStatus, ProgramContext, UserDataContext } from '../../../../contexts/context';
import { useParams } from 'react-router-dom';
import { getAppRefById } from '../../../../firebase/applications/getAppRefById';
import { deleteUploadedFile } from '../../firebase/deleteUploadedFile';
import showNotification from '../../../NotificationService/helpers/showNotification';
import { Timestamp } from 'firebase/firestore';
import addToLog from '../../../../firebase/addToLog';
import { getUpdatedExtraFileList } from '../../helpers/legacy-documents/getUpdatedExtraFileList';
import { uploadBytesResumable } from 'firebase/storage';
import { getPercent } from '../../../../utils/getPercent';
import { getArrayWithUpdatedPropById } from '../../helpers/legacy-documents/getArrayWithUpdatedPropById';
import { storageDocumentsPath } from '../../firebase/storageDocumentsPath';
import { updateUploadPath } from '../../helpers/legacy-documents/updateUploadPath';
import { getUploadedFileDownloadURL } from '../../firebase/getUploadedFileDownloadURL';
import { updateUploadedFilesInfo } from '../../firebase/updateUploadedFilesInfo';
import createExtraFileList from '../../helpers/legacy-documents/createExtraFileList';
import deleteFileFromFileList from '../../helpers/deleteFileFromFileList';
import { getFileRef } from '../../../../firebase/documents/documents';

const  LegacyDocumentsContent = ({uploadedDocs, curAppDocumentId}) => {

  const { role } = useContext(UserDataContext);

  const [ fileListState, setFileListState ] = useState(() => createExtraFileList(uploadedDocs));
  const [ uploadButtonIsDisabled, setUploadButtonIsDisabled ] = useState(false);
  const [ deletingFile, setDeletingFile ] = useState(false);
  const { accesses } = useContext(ApplicationStatus);
  const { notificationAPI } = useContext(ProgramContext)
  const { authorizedUser } = useContext(UserDataContext)
  const { appId } = useParams();
  const APPLICATION_REF = getAppRefById(appId);

  useEffect(() => {
    setFileListState(createExtraFileList(uploadedDocs));
  }, [uploadedDocs])

  // объявлены снаружи, т.к. метод handleUploadChange вызывается для каждого загружаекмого файла отдельно.
  let newUploadedFilesInfo = [];
  let promises = [];
  let newfilesInfoReady = uploadedDocs.length;

  const handleDelete = async (file) => {
    try {
      setDeletingFile(true);
      setFileListState((prev) => prev.filter(fileInfo => fileInfo.uid !== file.uid ))
      const updatedDocs = deleteFileFromFileList(uploadedDocs, file.uid)
      await deleteUploadedFile(updatedDocs, file.link, APPLICATION_REF)
      showNotification(notificationAPI, 'process', {processName: 'deleteUploadedFile', status: 'success',})
      const logData = {
        id: file.uid,
        changedAt: Timestamp.now(),
        documentName: file.name,
        size: '', // объект удаляемого файла не предоставляет вес файла.
        operatorId: authorizedUser.id,
        operatorName: authorizedUser.name,
        operationType: 'delete',
      }
      await addToLog(logData, getAppRefById(curAppDocumentId), 'documents_history')
    } catch(e) {
      console.log(e)
      showNotification(notificationAPI, 'process', {processName: 'deleteUploadedFile', status: 'error',})
      // Firebase Storage: Object 'documents/hJ1goDbnR8C8OMygvtm2-1690235227709.pdf' does not exist. (storage/object-not-found) - пример.
    } finally {
      setDeletingFile(false);
    }
  }


  // Вызывается для каждого файла из списка
  const beforeUpload = (file, _fileList) => {
    const fileType = file.type.split('/').at(-1);
    const fileSizeMb = (file.size/1024/1024).toFixed(1);
    if((fileType !== 'jpeg' && fileType !== 'pdf') || fileSizeMb > 30 ) {
      showNotification(notificationAPI, 'process', {processName: 'uploadFile', status: 'error'})
      return Upload.LIST_IGNORE;
    }
    setFileListState((prev) => getUpdatedExtraFileList(prev, file));
    return false; // предотвращает загрузку по адресу - пропс action компонента upload
  }

  // Вызывается для каждого файла из списка при смене статуса файла (Если удаление - только для одного)
  const handleUploadChange  = async (uploadInfo) => {
    // в случае удаления---------------------------------------------------
    if(uploadInfo.file.status === "removed") {
      return;
    }
    setUploadButtonIsDisabled(true);
    // загрузка в storage---------------------------------------------------
    newUploadedFilesInfo.push({name: uploadInfo.file.name, link: '', uid: uploadInfo.file.uid, downloadURL:''})
    const uploadResult = uploadBytesResumable(getFileRef(`${storageDocumentsPath.visaDocuments}/${appId}-${Date.now()}`), uploadInfo.file);
    promises.push(uploadResult);

    uploadResult.on('state_changed',
    (snapshot) => {
      const percentTransferred = getPercent(snapshot.bytesTransferred, snapshot.totalBytes);
      setFileListState((prev) => getArrayWithUpdatedPropById(prev, uploadInfo.file.uid, 'percent', percentTransferred ));
    }, error => {
      // TODO: обработать ошибки.
      console.log(error)
    }, async () => {
      newUploadedFilesInfo = updateUploadPath(newUploadedFilesInfo, uploadInfo.file.uid, uploadResult.snapshot.metadata.fullPath)
      const downloadURL = await getUploadedFileDownloadURL(uploadResult.snapshot.metadata.fullPath);
      setFileListState((prev) => getArrayWithUpdatedPropById(prev, uploadInfo.file.uid, 'url', downloadURL ))
      newUploadedFilesInfo = getArrayWithUpdatedPropById(newUploadedFilesInfo, uploadInfo.file.uid, 'downloadURL', downloadURL)
      const logData = {
        id: uploadInfo.file.uid,
        changedAt: Timestamp.now(),
        documentName: uploadInfo.file.name,
        size: uploadInfo.file.size,
        operatorId: authorizedUser.id,
        operatorName: authorizedUser.name,
        operationType: 'upload',
      }
      await addToLog(logData, getAppRefById(curAppDocumentId), 'documents_history')
      ++newfilesInfoReady;


      // загрузка информации в БД когда все объекты массива newUploadedFilesInfo будут готовы.
      if (newfilesInfoReady === uploadInfo.fileList.length) {
        try {
          await Promise.all(promises);
          const newUploadedDocs = [...uploadedDocs, ...newUploadedFilesInfo];
          await updateUploadedFilesInfo(APPLICATION_REF, {'preparedInformation.documents': newUploadedDocs});
          showNotification(notificationAPI, 'process', {processName: 'uploadFile', status: 'success',})
          setUploadButtonIsDisabled(false);
        } catch (e) {
          console.log(e)
          showNotification(notificationAPI, 'process', {processName: 'deleteUploadedFile', status: 'error',})
          setUploadButtonIsDisabled(false);
        }
      }
    })
  }


  return (
    <Upload
      disabled={!accesses.canUploadDocuments || uploadButtonIsDisabled}
      progress={{
        strokeColor: {
          '0%': '#108ee9',
          '100%': '#87d068',
        },
        size: [200, 4],
        showInfo: true,
        format: (percent) => `${percent}%`,
      }}
      fileList={fileListState}
      multiple
      accept="application/pdf, image/jpeg, .pdf, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi"
      beforeUpload={beforeUpload}
      onChange={handleUploadChange}
      onRemove={handleDelete}
      showUploadList={{showRemoveIcon: deletingFile || role === 'operator' ? false : true }}
    >
      <Button disabled={!accesses.canUploadDocuments || uploadButtonIsDisabled} icon={<UploadOutlined/>}>Загрузить файл</Button>
    </Upload>
  );
};

export default LegacyDocumentsContent;