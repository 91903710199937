import { getDownloadURL } from "firebase/storage";
import { getFileRef } from "../../../firebase/documents/documents";

export const getUploadedFileDownloadURL = async (path) => {
  try {
    const downloadURL = await getDownloadURL(getFileRef(path))
    return downloadURL;
  } catch (error) {
    throw error;
  }
};
