import React, { useLayoutEffect, useRef, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { Button } from 'antd';
import {SendOutlined } from "@ant-design/icons"

import '../../assets/components/team-chat.scss';
import TeamChatMessagesList from './TeamChatMessagesList';

const TeamChat = ({
  commentsData, commentsLoading, commentsError, sendComment
}) => {
  const [ text, setText ] = useState('');
  const scrollRef = useRef(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView(false);
    }, 1)
  }, [commentsData])

  const handleChange = (e)=> {
    setText(e.target.value);
  }

  const handleSend = () => {
    sendComment(text);
    setText('');
  }

  const keyDownHandler = (e) => {
    if(e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  }

  return (
    <div className='team-chat__chat-container'>
      <div className='team-chat__messages-list'>
        <TeamChatMessagesList
          commentsData={commentsData}
          commentsLoading={commentsLoading}
          commentsError={commentsError}
        />
        <span ref={scrollRef} ></span>
      </div>
      <div className="team-chat__leave-message-container">
        <div className="team-chat__textarea-container">
          <TextArea
            value={text}
            classNames={{textarea:'team-chat__textarea'}}
            autoSize={{minRows: 1, maxRows: 6}}
            placeholder='Напишите сообщение...'
            onKeyDown={keyDownHandler}
            onChange={handleChange}
          />
        </div>
        <div className="team-chat__send-button-container">
          <Button
            className='team-chat__send-button'
            icon={<SendOutlined className='team-chat__send-button-icon'/>}
            onClick={handleSend}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamChat;