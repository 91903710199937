import React, { useEffect, useState } from 'react';
import { Spin, Upload } from 'antd';
import '../../assets/components/team-files.scss'
import isFilesNumInvalid from '../../utils/isFilesNumInvalid';
import FilesNum from '../FilesNum/FilesNum';
import FileList from '../FileList/FileList';
import ErrorList from '../ErrorList/ErrorList';
import TeamUpload from './TeamUpload';

const TeamFiles = ({
  docs,
  maxDocs,
  isLoading,
  beforeUploadInner,
  deleteFileInner,
  uploadIsDisabled,
}) => {
  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ isMaxDocsError, setIsMaxDocsError ] = useState(false)
  
  useEffect(() => {
    if(isMaxDocsError) {
      setTimeout(() => {
        setIsMaxDocsError(false);
      }, 6000)
    }
  }, [isMaxDocsError])

  const handleDelete = (fileInfo) => {
    try {
      setIsDeleting(true)
      deleteFileInner(fileInfo)
    } catch (error) {
      console.log(error)
    } finally {
      setIsDeleting(false)
    }
  }

  const handleBeforeUpload = (file, fileList) => {
    if (isFilesNumInvalid(fileList, docs.length, maxDocs)) {
      setIsMaxDocsError(true)
      return Upload.LIST_IGNORE;
    }
    beforeUploadInner(file, fileList);
  }

  return (
    <div className="team-files__container">
      <h3 className="team-files__title">
        <span>Документы:</span>
        <TeamUpload
          docs={docs}
          maxDocs={maxDocs}
          uploadIsDisabled={uploadIsDisabled}
          beforeUploadHandler={handleBeforeUpload}
        />
        <FilesNum
          filesLenght={docs.length}
          maxNum={maxDocs}
        />
        {isDeleting ? (
          <div className='team-files__deleting-spinner'>
            <Spin size='small'></Spin>
          </div>
        ) : (
          null
        )}
      </h3>
      <div className="team-files__files">
        <div className="team-files__file-list">
          <FileList
            fileList={docs}
            onDelete={handleDelete}
            disableDeletion={isLoading}
          />
        </div>
        <ErrorList isMaxDocsError={isMaxDocsError} maxDocs={maxDocs}/>
      </div>
    </div>
  );
};

export default TeamFiles;