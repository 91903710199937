import { collection, doc, orderBy, query, setDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { applicationsPath } from "./appsPath";

const getAppTeamChatRef = (appId) => {
  return collection(firestore, applicationsPath.applications, appId, applicationsPath.applicationTeamChat)
}

export const getAppTeamChatMessageRef = (appId) => {
  return doc(getAppTeamChatRef(appId));
};

export const getTeamChatMessagesRefQuery = (appId) => {
  return query(getAppTeamChatRef(appId), orderBy('createdAt'))
}

export const addAppTeamChatMessage = async (appId, msg) => {
  try {
    const appTeamChatMessageRef = getAppTeamChatMessageRef(appId);
    await setDoc(appTeamChatMessageRef, {...msg, docId: appTeamChatMessageRef.id})
  } catch (error) {
    console.log(error)
    throw error;
  }
}
