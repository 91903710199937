import React from 'react';
import '../../assets/components/team-chat-person-message.scss';
import dayjs from 'dayjs';

const TeamChatPersonMessage = ({comment}) => {
  const time = dayjs(comment.createdAt.toMillis()).format('YYYY.MM.DD HH:mm')
  const commentByAssistent = comment.author.role === 'assistant';

  return (
    <div className='person-message__container'>
      <div className={`person-message__inner ${commentByAssistent ? 'assistant' : ''}`}>
        <div className='person-message__metadata'>
          <div className='person-message__author'>
            {comment.author.name}&nbsp;
          </div>
          <div className='person-message__time'>
            {time}
          </div>
        </div>
        <p className='person-message__text'>{comment.text}</p>
      </div>
    </div>
  );
};

export default TeamChatPersonMessage;