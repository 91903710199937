import { Upload } from 'antd';
import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import '../../assets/components/team-upload.scss';

const TeamUpload = ({docs, maxDocs, uploadIsDisabled, beforeUploadHandler}) => {

  return (
    <div className='team-upload__container'>
      <Upload
        disabled={uploadIsDisabled}
        progress={{
          strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
          },
          size: [200, 4],
          showInfo: true,
          format: (percent) => `${percent}%`,
        }}
        fileList={docs}
        multiple={maxDocs > 1}
        accept="application/pdf, image/jpeg, .pdf, .jpg, .jpeg, .jpe .jif, .jfif, .jfi"
        beforeUpload={beforeUploadHandler}
        showUploadList={false}
      >
        <div className={`team-upload__icon-container ${uploadIsDisabled ? 'upload-not-allowed' : ''}`}>
          <UploadOutlined className='team-upload__upload-icon' style={{margin:'0 5px'}}/>
        </div>
      </Upload>
    </div>
  );
};

export default TeamUpload;