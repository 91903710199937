import { deleteObject } from "firebase/storage";
import { firestore,  } from "../../../firebase/firebase";
import { runTransaction, serverTimestamp } from "firebase/firestore";
import getDocSectionRef from "./getDocSectionRef";
import { getAppRefById } from "../../../firebase/applications/getAppRefById";
import getDocSectionCommentRef from "./getDocSectionCommentRef";
import { getFileRef } from "../../../firebase/documents/documents";

export const deleteUploadedFile = async (uploadedDocs, path, serviceMessageData, appId, applicantId, sectionId) => {
  try {
    const promises = [];
    const storageFileRef = getFileRef(path);
    promises.push(deleteObject(storageFileRef)); // удаление файла из storage
    promises.push(runTransaction(firestore, async (transaction) => { // удаление записи об этом файле из firestore и обновление таймштампа последней активности в секции.
      transaction
      .update(getAppRefById(appId), {"preparedInformation.documents": uploadedDocs})
      .set(getDocSectionRef(appId, applicantId, sectionId), {lastActivity: serverTimestamp(), isRead: false}, {merge: true})
      .set(getDocSectionCommentRef(appId, applicantId, sectionId, serviceMessageData.id), serviceMessageData)
    }))
    await Promise.all(promises)
  } catch (e) {
    throw e
  }
};
