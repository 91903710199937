import { Tabs, Upload } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import DocumentSections from './DocumentSections';
import { DocsContentContext, ProgramContext, UserDataContext } from '../../../../contexts/context';
import { addFileToFileList } from '../../helpers/docs-by-sections/addFileToFileList';
import updateFileProp from '../../helpers/docs-by-sections/updateFileProp';
import { useParams } from 'react-router-dom';
import showNotification from '../../../NotificationService/helpers/showNotification';
import isFileInvalid from '../../validation/isFileInvalid';
import { storageDocumentsPath } from '../../firebase/storageDocumentsPath';
import uploadDocuments from '../../firebase/uploadDocuments';
import getUpdatedDocuments from '../../helpers/docs-by-sections/getUpdatedDocuments';
import { deleteUploadedFile } from '../../firebase/deleteUploadedFile';
import createInitialDocumentsState from '../../models/createInitialDocumentsState';
import addToHistory from '../../firebase/addToHistory';
import { runTransaction, serverTimestamp } from 'firebase/firestore';
import { firestore } from '../../../../firebase/firebase';
import updateUploadedDocsInfo from '../../firebase/updateUploadedDocsInfo';
import { nanoid } from 'nanoid';
import getFilesInfoForDocSectionJournal from '../../models/getFilesInfoForDocSectionJournal';
import getDocSectionRef from '../../firebase/getDocSectionRef';
import getDocSectionCommentRef from '../../firebase/getDocSectionCommentRef';

const DocumentsContent = ({passports, uploadedDocs, countryCode, servicePrice, citizenship, assistantsData}) => {
  // states
  const [ documentsState, setDocumentsState ] = useState(() => createInitialDocumentsState(passports, uploadedDocs, countryCode, servicePrice));
  const [ isLoading, setIsLoading ] = useState(false);
  // contexts
  const { notificationAPI } = useContext(ProgramContext)
  const { authorizedUser } = useContext(UserDataContext)
  // react-router hooks
  const { appId } = useParams();

  useEffect(() => {
    setDocumentsState(createInitialDocumentsState(passports, uploadedDocs, countryCode, servicePrice));
  }, [uploadedDocs, passports, countryCode, servicePrice])

  const assignAssistant = async (applicantId, sectionId, assignData, serviceMessageData) => {
    try {
      await runTransaction(firestore, async (transaction) => {
        transaction
        .set(getDocSectionRef(appId, applicantId, sectionId), assignData, {merge: true})
        .set(getDocSectionCommentRef(appId, applicantId, sectionId, serviceMessageData.id), serviceMessageData)
      })
      showNotification(notificationAPI, 'process', {processName: 'assignAssistant', status: 'success',});
    } catch (error) {
      console.log(error)
      showNotification(notificationAPI, 'process', {processName: 'assignAssistant', status: 'error',});
    }
  }

  const addFileToDocumentsState = (applicantId, sectionId, file ) => {
    setDocumentsState(prev => {
      const clone = structuredClone(prev);
      const sectionIndex = clone[applicantId].docSections.findIndex(section => section.sectionId === sectionId);
      const cloneDocs = sectionIndex !== -1 ? clone[applicantId].docSections[sectionIndex].docs : [];
      clone[applicantId].docSections[sectionIndex].docs =  addFileToFileList(cloneDocs, file)
      return clone;
    })
  }

  const changeFilePropsDocumentsState = (applicantId, sectionId, fileId, newData) => {
    setDocumentsState(prev => {
      const clone = structuredClone(prev);
      const sectionIndex = clone[applicantId].docSections.findIndex(section => section.sectionId === sectionId);
      const cloneDocs = sectionIndex !== -1 ? clone[applicantId].docSections[sectionIndex].docs : [];
      clone[applicantId].docSections[sectionIndex].docs = updateFileProp(cloneDocs, fileId, newData);
      return clone;
    })
  }

   // Вызывается для каждого файла из списка
  const beforeUpload = async (file, fileList, applicantId, section) => {
    if(isFileInvalid(file)) {
      showNotification(notificationAPI, 'process', {processName: 'uploadFile', status: 'error'})
      return Upload.LIST_IGNORE;
    }
    addFileToDocumentsState(applicantId, section.sectionId, file);
    // запуск загрузки
    if(file.uid === fileList.at(-1).uid) {
      const serviceMessageData = {
        author: {
          id:authorizedUser.id,
          name: authorizedUser.name,
          role: authorizedUser.role,
        },
        createdAt: serverTimestamp(),
        id: nanoid(),
        operation: {
          type: 'uploadFile',
          filesInfo: getFilesInfoForDocSectionJournal(fileList),
        },
        type:'service',
      }
      uploadFiles(fileList, applicantId, section, serviceMessageData);
    }
  }

  const uploadFiles = async (fileList, applicantId, section, serviceMessageData) => {
    try {
      setIsLoading(true); // для глобальной блокировки загрузки и удаления документов.
      const storagePath = `${storageDocumentsPath.visaDocuments}/${appId}/${applicantId}-${section.sectionId}`;
      const preparedDocsData = await uploadDocuments(fileList, storagePath, applicantId, section.sectionId, changeFilePropsDocumentsState);
      const newDocsArray =  [...section.docs, ...preparedDocsData];
      const updatedDocuments = getUpdatedDocuments(documentsState, 'add', {applicantId, sectionId: section.sectionId, newDocsArray});
      await updateUploadedDocsInfo(appId, updatedDocuments,  applicantId, section.sectionId, serviceMessageData)
      await addToHistory(authorizedUser, appId, {type: 'upload', fileList})
      showNotification(notificationAPI, 'process', {processName: 'uploadFile', status: 'success',});
    } catch (error) {
      console.log(error)
      showNotification(notificationAPI, 'process', {processName: 'uploadFile', status: 'error',});
    } finally {
      setIsLoading(false);
    }
  }

  const deleteFile = async (applicantId, sectionId, fileExtra, serviceMessageData) => {
    try {
      setIsLoading(true) // для глобальной блокировки загрузки и удаления документов.
      const updatedDocuments = getUpdatedDocuments(documentsState, 'delete', {applicantId, sectionId, deletingFileId: fileExtra.uid});
      await deleteUploadedFile(updatedDocuments, fileExtra.link, serviceMessageData, appId, applicantId, sectionId);
      await addToHistory(authorizedUser, appId, {type: 'delete', file:fileExtra})
      showNotification(notificationAPI, 'process', {processName: 'deleteUploadedFile', status: 'success',});
    } catch (error) {
      console.log(error)
      showNotification(notificationAPI, 'process', {processName: 'deleteUploadedFile', status: 'error',});
    } finally {
      setIsLoading(false);
    }
  }

  const changeSectionStatus = async (statusData, applicantId, sectionId, serviceMessageData) => {
    try {
      await runTransaction(firestore, async (transaction) => {
        transaction
        .update(getDocSectionRef(appId, applicantId, sectionId), statusData)
        .set(getDocSectionCommentRef(appId, applicantId, sectionId, serviceMessageData.id), serviceMessageData)
      })
      showNotification(notificationAPI, 'process', {processName: 'changeDocSectionStatus', status: 'success',});
    } catch (error) {
      showNotification(notificationAPI, 'process', {processName: 'changeDocSectionStatus', status: 'error',});
    }
  }

  const items = documentsState.map((applicant, index) => {
    return {
      key: index,
      label:`${index + 1}. ${applicant.applicantName}`,
      children: (
        <DocumentSections
          sections={applicant.docSections}
          applicantId={index}
          applicantName={applicant.applicantName}
        />
      )
    }
  })

  const context={
    isLoading,
    beforeUpload,
    deleteFile,
    assistantsData,
    assignAssistant,
    applicantsNum: passports.length,
    countryCode,
    citizenship,
    changeSectionStatus
  }

  return (
    <DocsContentContext.Provider value={context}>
      <Tabs
        defaultActiveKey="1"
        items={items}
        tabPosition='left'
        tabBarGutter={0}
        size='small'
        style={{width:'100%'}}
      />
    </DocsContentContext.Provider>
  );
};

export default DocumentsContent;