import { deleteObject, ref, uploadBytesResumable } from "firebase/storage"
import { firestore, storage } from "../firebase"
import { getPercent } from "../../utils/getPercent"
import { getUploadedFileDownloadURL } from "../../modules/Documents/firebase/getUploadedFileDownloadURL"
import { runTransaction } from "firebase/firestore"
import { getAppRefById } from "../applications/getAppRefById"
import { getAppTeamChatMessageRef } from "../applications/app-team-chat"

const docPaths = {
  storageTeamDocs: 'team-docs'
}

export const getFileRef = (path) => {
  return ref(storage, path)
}

export const getTeamDocsStoragePath = (appId) => {
  return `${docPaths.storageTeamDocs}/${appId}/${Date.now()}`
}

export const uploadDocuments = async (fileList, appId, changeFilePropsDocumentsState) => {
  const uploadResultPromises = []
  const downloadURLPromises = []
  const preparedDocsData = [];

  fileList.forEach((file, index) => {
    const newDocDataObj = { // подготавлеваемый объект документа для загрузки в БД.
      uid: file.uid,
      name: file.name,
      downloadURL:'',
      link:'',
    }
    preparedDocsData.push(newDocDataObj);
    const storagePath = getTeamDocsStoragePath(appId);

    const uploadResult = uploadBytesResumable(getFileRef(storagePath), file);
    uploadResultPromises.push(uploadResult);
    uploadResult.on('state_changed', (snapshot) => {
      const percentTransferred = getPercent(snapshot.bytesTransferred, snapshot.totalBytes);
      changeFilePropsDocumentsState(file.uid, {'percent': percentTransferred}) // обновляет проценты загрузки в списке файлов.
    }, (error) => {
      console.log(error)
      throw error;
    }, () => {
      // загрузка завершена
      const newDocDataIndex = preparedDocsData.findIndex(docData => docData.uid === file.uid);
      preparedDocsData[newDocDataIndex].link = uploadResult.snapshot.metadata.fullPath;
      const downloadURL = getUploadedFileDownloadURL(uploadResult.snapshot.metadata.fullPath).then(res => {
        preparedDocsData[newDocDataIndex].downloadURL = res;
        changeFilePropsDocumentsState(file.uid, {'downloadURL': res, 'status': 'done'})
        return res;
      });
      downloadURLPromises.push(downloadURL)
    })
  })

  await Promise.all(uploadResultPromises);
  await Promise.all(downloadURLPromises);
  return preparedDocsData;
}

export const updateAppTeamDocs = async (appId, updatedDocuments, serviceMessageData) => {
  try {
    await runTransaction(firestore, async (transaction) => {
      const teamChatMessageRef = getAppTeamChatMessageRef(appId)

      transaction
      .update(getAppRefById(appId), {'teamDocs': updatedDocuments})
      .set(teamChatMessageRef, {...serviceMessageData, docId: teamChatMessageRef.id})
    })
  } catch (error) {
    throw error;
  }
}

export const deleteUploadedFile = async (docs, path, serviceMessageData, appId) => {
  try {
    const promises = [];
    const storageFileRef = getFileRef(path);
    const teamChatMessageRef = getAppTeamChatMessageRef(appId)

    promises.push(deleteObject(storageFileRef)); // удаление файла из storage
    promises.push(runTransaction(firestore, async (transaction) => { // удаление записи об этом файле из firestore и обновление таймштампа последней активности в секции.
      transaction
      .update(getAppRefById(appId), {'teamDocs': docs})
      .set(teamChatMessageRef,{...serviceMessageData, docId: teamChatMessageRef.id})
    }))
    await Promise.all(promises)
  } catch (e) {
    throw e
  }
};