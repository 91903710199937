import { uploadBytesResumable } from "firebase/storage";
import { getPercent } from "../../../utils/getPercent";
import { getUploadedFileDownloadURL } from "./getUploadedFileDownloadURL";
import { getFileRef } from "../../../firebase/documents/documents";

const uploadDocuments = async (fileList, path, applicantId, sectionId, changeFilePropsDocumentsState, ) => {
  const uploadResultPromises = []
  const downloadURLPromises = []
  const preparedDocsData = [];

  fileList.forEach((file, index) => {
    const newDocDataObj = { // подготавлеваемый объект документа для загрузки в БД.
      uid: file.uid,
      name: file.name,
      downloadURL:'',
      link:'',
    }
    preparedDocsData.push(newDocDataObj);

    const uploadResult = uploadBytesResumable(getFileRef(`${path}-${Date.now()}-${index}`), file);
    uploadResultPromises.push(uploadResult);

    uploadResult.on('state_changed',
      (snapshot) => {
        const percentTransferred = getPercent(snapshot.bytesTransferred, snapshot.totalBytes);
        changeFilePropsDocumentsState(applicantId, sectionId, file.uid, {'percent': percentTransferred}) // обновляет проценты загрузки в списке файлов.
      }, error => {
        console.log(error)
        throw error
      }, () => {
        // загрузка завершена
        const newDocDataIndex = preparedDocsData.findIndex(docData => docData.uid === file.uid);
        preparedDocsData[newDocDataIndex].link = uploadResult.snapshot.metadata.fullPath;
        const downloadURL = getUploadedFileDownloadURL(uploadResult.snapshot.metadata.fullPath).then(res => {
          preparedDocsData[newDocDataIndex].downloadURL = res;
          changeFilePropsDocumentsState(applicantId, sectionId, file.uid, {'downloadURL': res, 'status': 'done'})
          return res;
        });
        downloadURLPromises.push(downloadURL)
      }
    )
  })

  await Promise.all(uploadResultPromises);
  await Promise.all(downloadURLPromises);
  return preparedDocsData;
}

export default uploadDocuments;
