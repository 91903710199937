import React from 'react';
import '../../assets/components/team-chat-service-message.scss';
import dayjs from 'dayjs';

const TeamChatServiceMessage = ({ comment }) => {
  const time = dayjs(comment.createdAt.toMillis()).format('DD/MM/YY HH:mm');

  let who;
  let did;
  let what;

  if(comment.operation.type === 'assignAssistant') {
    who = comment.author.name;
    did = ` назначил(a) ассистента: `
    what = comment.operation.assistantName;
  }
  if(comment.operation.type === 'changeSectionStatus') {
    who = comment.author.name;
    did = ` сменил(a) статус: `
    const oldStatus = comment.operation.oldStatusTitle;
    const newStatus = comment.operation.newStatusTitle;
    what = <span>{oldStatus} &#10511; {newStatus}</span>
  }
  if(comment.operation.type === 'deleteFile') {
    who = comment.author.name;
    did = ` удалил(а) файл: `;
    what = comment.operation.fileName;
  }
  if(comment.operation.type === 'uploadFile') {
    who = comment.author.name;
    did = ` добавил(а) файл(ы): `;
    what = (
      <div>
        {comment.operation.filesInfo.map(fileInfo => {
          return <div key={fileInfo.fileId}>{fileInfo.fileName}</div>
        })}
      </div>
    )
  }

  return (
    <div className='service-message__container'>
      <div className='service-message__inner'>
        <span>{`${time}: `}</span>
        <span className='service-message__author-name'>{who}</span>
        <span>{did}</span>
        <span className='service-message__assistant-name'>{what}</span>
      </div>
    </div>
  );
};

export default TeamChatServiceMessage;