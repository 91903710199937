import { uploadBytesResumable } from "firebase/storage";
import { getFileRef } from "../../../firebase/documents/documents";

const uploadPicture = (newsId, file) => {
  return new Promise((resolve, reject) => {
    const uploadResult = uploadBytesResumable(getFileRef(`news/${newsId}/${Date.now()}`), file);
    uploadResult.on('state_changed', (snapshot) => {
      // on state change code
    }, (error) => {
      reject(error)
    }, () => {
      resolve(uploadResult)
    })
  })
}

export default uploadPicture;