import React, { useContext } from 'react';
import '../../../../assets/documents/docs-by-sections/section-content.scss'
import SectionContentTitle from './SectionContentTitle';
import { ApplicationStatus, DocsContentContext, DocSectionContext, UserDataContext } from '../../../../contexts/context';
import Spinner from '../../../../components/Spinner';
import MyError from '../../../Error/components/MyError';
import { useParams } from 'react-router-dom';
import createCommentObj from '../../models/createCommentObj';
import leaveComment from '../../firebase/leaveComment';
import createDeleteFileOperationData from '../../models/createDeleteFileOperationData';
import createServiceCommentData from '../../models/createServiceCommentData';
import TeamChat from '../../../../components/TeamChat/TeamChat';
import TeamFiles from '../../../../components/TeamFiles/TeamFiles';

const SectionContent = () => {
  const { docSectionDataLoading, docSectionDataError, applicantId, section, commentsData, commentsLoading, commentsError } = useContext(DocSectionContext);
  const { authorizedUser } = useContext(UserDataContext);
  const { appId } = useParams();
  const { isLoading, deleteFile, beforeUpload } = useContext(DocsContentContext);
  const { accesses } = useContext(ApplicationStatus);

  const sendComment = async (text) => {
    // TODO: добавить смену даты активности и в загрузку и удаление файла.
    try {
      const comment = createCommentObj(text, authorizedUser);
      await leaveComment(appId, applicantId, section.sectionId, comment)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteFileInner = async (fileInfo) => {
    try {
      const deleteFileOperation = createDeleteFileOperationData(fileInfo.name, fileInfo.uid)
      const serviceCommentData = createServiceCommentData(authorizedUser, deleteFileOperation)
      await deleteFile(applicantId, section.sectionId, fileInfo, serviceCommentData);
    } catch (error) {
      console.log(error)
    }
  }

  const beforeUploadInner = (file, fileList) => {
    beforeUpload(file, fileList, applicantId, section)
    return false; // предотвращает загрузку по дефолтному адресу компонента Upload.
  }

  if(docSectionDataLoading) {
    return <Spinner />
  }

  if(docSectionDataError) {
    return <MyError error={docSectionDataError} />
  }

  const uploadIsDisabled = !accesses.canUploadDocuments || isLoading || (section.docs.length === section.maxDocs);

  return (
    <div className='section-content__container'>
      <SectionContentTitle />
      <div className="section-content__content">
        <TeamChat
          commentsData={commentsData}
          commentsLoading={commentsLoading}
          commentsError={commentsError}
          sendComment={sendComment}
        />
        <TeamFiles
           docs={section.docs}
           maxDocs={section.maxDocs}
           isLoading={isLoading}
           beforeUploadInner={beforeUploadInner}
           deleteFileInner={deleteFileInner}
           uploadIsDisabled={uploadIsDisabled}
        />
      </div>
    </div>
  );
};

export default SectionContent;