import { uploadBytesResumable } from "firebase/storage";
import { getPercent } from "../../utils/getPercent";
import getUploadedFileDownloadURL from "../getUploadedFileDownloadURL";
import { getFileRef } from "../documents/documents";

const downloadNewsFiles = async (fileList, path, setFileList) => {
  const uploadResults = [];
  const urls = []
  const dataToUpload = []
  fileList.forEach(file => {
    const newDocDataObj = { // подготавлеваемый объект документа для загрузки в БД.
      uid: file.uid,
      name: file.name,
      downloadURL:'',
      link:'',
    }
    const uploadResult = uploadBytesResumable(getFileRef(`${path}/file-${Date.now()}`), file.fileOrigin);
    uploadResults.push(uploadResult);
    uploadResult.on('state_changed', (snapshot) => {
      const percentTransferred = getPercent(snapshot.bytesTransferred, snapshot.totalBytes);
      setFileList(prev => prev.map(fileInfo => fileInfo.uid === file.uid ? {...fileInfo, status:'uploading', percent: percentTransferred} : fileInfo));
    }, error => {
      console.log(error)
      throw error;
    }, () => {
      newDocDataObj.link = uploadResult.snapshot.metadata.fullPath;
      const downloadURL = getUploadedFileDownloadURL(uploadResult.snapshot.metadata.fullPath).then(res => {
        newDocDataObj.downloadURL = res;
        dataToUpload.push(newDocDataObj);
        return res;
      })
      urls.push(downloadURL);
    })
  })
  await Promise.all(uploadResults);
  await Promise.all(urls);
  return dataToUpload;
}

export default downloadNewsFiles;