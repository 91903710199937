import React from 'react';
import { Spin } from 'antd';
import ErrorList from '../ErrorList/ErrorList';
import TeamChatServiceMessage from './TeamChatServiceMessage';
import TeamChatPersonMessage from './TeamChatPersonMessage';

const TeamChatMessagesList = ({commentsData, commentsLoading, commentsError}) => {

  if( commentsLoading ) {
    return <Spin></Spin>
  }
  if(commentsError) {
    return <ErrorList error={commentsError}/>
  }

  return commentsData.map(comment => {
    if (comment.type === 'service') {
      return <TeamChatServiceMessage key={comment.id} comment={comment} />
    }
    return <TeamChatPersonMessage key={comment.id} comment={comment} />
  })
};

export default TeamChatMessagesList;