import React, {useState, useEffect, useContext} from 'react';
import { Image, Alert } from 'antd';
import { getDownloadURL } from 'firebase/storage';
import getResponse from '../helpers/getResponse';
import { QuestionnaireContext } from '../../../contexts/context';
import { getFileRef } from '../../../firebase/documents/documents';

const TypePhotoAnswer = ({questionData, questionIndex, personIndex, nestedOptions={isNested:false}}) => {
  // не может редактировать
  const {answersToUpdate} = useContext(QuestionnaireContext);
  const [imgUrl, setImgUrl] = useState(null);

  const response = getResponse(answersToUpdate, questionData, questionIndex, nestedOptions, personIndex);

  useEffect(() => {
    if(response) {
      const func = async () => {
        const url = await getDownloadURL(getFileRef(response));
        setImgUrl(url)
      }
      func()
    }
  }, [response])

  if(!response) {
    return <Alert message={"Изображение не загружено!"} type={"warning"} showIcon/>
  }

  return (
    <Image
      width={50}
      src={imgUrl}
     />
  );
};

export default TypePhotoAnswer;
