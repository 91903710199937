import { FloatButton, Modal } from 'antd';
import React, { useState } from 'react';
import TeamContent from './TeamContent';
import { WechatWorkOutlined } from '@ant-design/icons';


const TeamApplicationChat = ({appId, teamDocs}) => {
  const [ modalIsOpened, setModalIsOpened ] = useState(false)

  const handleModalOpen = () => {
    setModalIsOpened(true);
  }

  const handleModalClose = () => {
    setModalIsOpened(false);
  }

  return (
    <>
      <FloatButton onClick={handleModalOpen}       type="primary"  icon={<WechatWorkOutlined />}    />
      {/* <Button size='small' type='primary' onClick={handleModalOpen}>Чат заявки</Button> */}
      <Modal
        open={modalIsOpened}
        centered
        onCancel={handleModalClose}
        width='80%'
        destroyOnClose={true}
        footer={null}
      >
        <TeamContent appId={appId} teamDocs={teamDocs}/>
      </Modal>
    </>
  );
};

export default TeamApplicationChat;