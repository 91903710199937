import React, { useContext, useEffect, useState } from 'react';
import TeamChat from '../../../components/TeamChat/TeamChat';
import TeamFiles from '../../../components/TeamFiles/TeamFiles';
import createCommentObj from '../../Documents/models/createCommentObj';
import { ApplicationStatus, ProgramContext, UserDataContext } from '../../../contexts/context';
import { serverTimestamp } from 'firebase/firestore';
import { addAppTeamChatMessage } from '../../../firebase/applications/app-team-chat';
import isFileInvalid from '../../Documents/validation/isFileInvalid';
import { Row, Typography, Upload } from 'antd';
import showNotification from '../../NotificationService/helpers/showNotification';
import { deleteUploadedFile, updateAppTeamDocs, uploadDocuments } from '../../../firebase/documents/documents';
import { nanoid } from 'nanoid';
import getFilesInfoForDocSectionJournal from '../../Documents/models/getFilesInfoForDocSectionJournal';
import createDeleteFileOperationData from '../../Documents/models/createDeleteFileOperationData';
import createServiceCommentData from '../../Documents/models/createServiceCommentData';
import useTeamChatMessages from '../hooks/useTeamChatMessages';
import '../../../assets/documents/team-content/team-content.scss';

const TEAM_MAX_DOCS = 2;

const TeamContent = ({appId, teamDocs}) => {
  const { authorizedUser } = useContext(UserDataContext);
  const [ isLoading, setIsLoading ] = useState(false);
  const { notificationAPI } = useContext(ProgramContext)
  const [ docs, setDocs ] = useState(teamDocs || []);
  const [ messagesData, messagesLoading, messagesError ] = useTeamChatMessages(appId);
  const { accesses } = useContext(ApplicationStatus);

  useEffect(() => {
    setDocs(teamDocs || [])
  }, [teamDocs])

  const sendMessage = async (text) => {
    // TODO: добавить смену даты активности и в загрузку и удаление файла.
    try {
      const msg = createCommentObj(text, authorizedUser);
      await addAppTeamChatMessage(appId, msg)
    } catch (error) {
      console.log(error)
    }
  }

 // Вызывается для каждого файла из списка
 const beforeUpload = async (file, fileList, appId) => {

  if(isFileInvalid(file)) {
    showNotification(notificationAPI, 'process', {processName: 'uploadFile', status: 'error'})
    return Upload.LIST_IGNORE;
  }

  setDocs(prev => [...prev,  {
    name: file.name,
    status: 'uploading', // чтобы Upload отобразил прогресс бар.
    uid: file.uid
  }]);

  // запуск загрузки
  if(file.uid === fileList.at(-1).uid) {
    const serviceMessageData = {
      author: {
        id:authorizedUser.id,
        name: authorizedUser.name,
        role: authorizedUser.role,
      },
      createdAt: serverTimestamp(),
      id: nanoid(),
      operation: {
        type: 'uploadFile',
        filesInfo: getFilesInfoForDocSectionJournal(fileList),
      },
      type:'service',
    }

    uploadFiles(fileList, appId, serviceMessageData);
  }
}

const changeFilePropsDocumentsState = (fileId, newData) => {
  setDocs(prev => prev.map(file => file.uid === fileId ? {...file, ...newData} : file))
}

const uploadFiles = async (fileList, appId, serviceMessageData) => {
  try {
    setIsLoading(true); // для глобальной блокировки загрузки и удаления документов.
    const preparedDocsData  = await uploadDocuments(fileList, appId, changeFilePropsDocumentsState);
    const updatedDocuments = [...docs, ...preparedDocsData];
    await updateAppTeamDocs(appId, updatedDocuments,  serviceMessageData)
    showNotification(notificationAPI, 'process', {processName: 'uploadFile', status: 'success',});
  } catch (error) {
    console.log(error)
    showNotification(notificationAPI, 'process', {processName: 'uploadFile', status: 'error',});
  } finally {
    setIsLoading(false);
  }
}


  const beforeUploadInner = (file, fileList) => {
    beforeUpload(file, fileList, appId )
    return false; // предотвращает загрузку по дефолтному адресу компонента Upload.
  }

  const deleteFile = async (fileExtra, serviceMessageData) => {
    try {
      setIsLoading(true) // для глобальной блокировки загрузки и удаления документов.
      const updatedDocuments = docs.filter( doc => doc.uid !== fileExtra.uid);
      await deleteUploadedFile(updatedDocuments, fileExtra.link, serviceMessageData, appId);
      showNotification(notificationAPI, 'process', {processName: 'deleteUploadedFile', status: 'success',});
    } catch (error) {
      console.log(error)
      showNotification(notificationAPI, 'process', {processName: 'deleteUploadedFile', status: 'error',});
    } finally {
      setIsLoading(false);
    }
  }

  const deleteFileInner = async (fileInfo) => {
    try {
      const deleteFileOperation = createDeleteFileOperationData(fileInfo.name, fileInfo.uid)
      const serviceCommentData = createServiceCommentData(authorizedUser, deleteFileOperation)
      await deleteFile(fileInfo, serviceCommentData);
    } catch (error) {
      console.log(error)
    }
  }
  const uploadIsDisabled = !accesses.canUploadDocuments || isLoading || (docs.length === TEAM_MAX_DOCS);

  return (
    <div className='team-app-content__container'>
      <Row justify='center'>
        <Typography.Title level={3}>
          Чат сотрудников заявки: {appId}
        </Typography.Title>
      </Row>
      <div className="team-app-content__content">
        <TeamChat
          commentsData={messagesData}
          commentsLoading={messagesLoading}
          commentsError={messagesError}
          sendComment={sendMessage}
        />
        <TeamFiles
          docs={docs}
          maxDocs={TEAM_MAX_DOCS}
          isLoading={isLoading}
          beforeUploadInner={beforeUploadInner}
          deleteFileInner={deleteFileInner}
          uploadIsDisabled={uploadIsDisabled}
        />
      </div>
    </div>
  );
};

export default TeamContent;