import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Layout, Row, Col, Spin } from "antd";
import Chat from "../../../modules/Chat/components/Chat";
import '../../../assets/application-work-area.scss';
import { getDocFromCollectionByFieldAndValue } from "../../../helpers/getDocFromCollectionByFieldAndValue";
import ApplicationToolBar from "../../../modules/ApplicationToolBar/components/ApplicationToolBar";
import ApplicantsPassports from "../../../modules/ApplicantPassports/components/ApplicantsPassports";
import ApplicationQuestionnaires from "../../../modules/ApplicationQuestionnaires/components/ApplicationQuestionnaires";
import { getAllFieldsFromDocSnapshot } from "../../../helpers/getAllFieldsFromDocSnapshot";
import Documents from "../../../modules/Documents/components/Documents";
import updateDocField  from "../../../firebase/updateDocField";
import { getCountry } from "../../../helpers/countries/getCountry";
import { ApplicationStatus, ProgramContext, UserDataContext } from "../../../contexts/context";
import checkAccesses from "../helpers/checkAccesses";
import ApplicationAdditionals from "../../../modules/ApplicationsInformation/components/ApplicationAdditionals";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { doc, query } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import ChatDataProvider from "../../../modules/Chat/components/ChatDataProvider";
import TeamApplicationChat from "../../../modules/TeamApplicationChat/components/TeamApplicationChat";

// хуки, загружающие данные реагируют на изменение query параметров только на следующем рендере.
// Поэтому если clientId изменится - в текущем рендере хуки не отреагируют.
// Поэтому компонент ApplicationContainer в случае смены clientId в URL - сперва отрендерит спиннер, а не ApplicationWorkArea.

const ApplicationWorkPage = ({clientId, allClientAppsData, allClientAppsCollSnapshot, chatCollSnapshot, clientData, countriesData, countriesDocSnapshot, assistantsData}) => {
  // react-router hooks
  const navigate = useNavigate();
  const { appId } = useParams();
  // контексты
  const { notificationAPI } = useContext(ProgramContext);
  const { authorizedUser, role } = useContext(UserDataContext)

  // стейты
  const [ curApplicationSnap, setCurApplicationSnap ] = useState(null);
  // загрузка
  const [ assignedOperator, assignedOperatorLoading, assignedOperatorError ] = useDocumentDataOnce(curApplicationSnap && curApplicationSnap.get('preparedInformation.assignedTo') ? query(doc(firestore, 'admins', curApplicationSnap.get('preparedInformation.assignedTo'))) : null);

  useEffect(() => {
    //наверный UID когда через URL заходим.
    if(allClientAppsCollSnapshot.empty) {
      navigate('/');
      // showNotification(notificationAPI, 'process', {processName:'noLongerAssignedTo', status:'warning'});
    }
  }, [allClientAppsCollSnapshot.empty, navigate, notificationAPI])

  useEffect(() => {
    // записав appId в URI - сохраняем соответствующую заявку.
    if(!allClientAppsCollSnapshot.empty) {
      // TODO: проверить заявку на наличие.
      const curApp = getDocFromCollectionByFieldAndValue(allClientAppsCollSnapshot, 'documentID', appId);
      if (!curApp) {
        // нет такого документа , если заходить через URL.
        navigate('/');
        // showNotification(notificationAPI, 'process', {processName:'noLongerAssignedTo', status:'warning'});
        return;
      }
      setCurApplicationSnap(curApp);
    }
  }, [allClientAppsCollSnapshot, appId, navigate, notificationAPI])

  useEffect(() => {
    if(curApplicationSnap && !curApplicationSnap.get('isRead') && (authorizedUser.id === curApplicationSnap.get('preparedInformation').assignedTo) ) {
      const viewApplication = async (ref) => {
        try {
          await updateDocField(ref, {isRead: true});
        } catch (error) {
          console.log(error);
        }
      }
      viewApplication(curApplicationSnap.ref);
    }
  },[authorizedUser.id, curApplicationSnap])

  if (!curApplicationSnap) {
    return (
      <div style={{height:"100vh", display:"flex", justifyContent:"center", alignItems:"center" }}>
        <Spin size="large"/>
      </div>
    )
  }

  const curApplication = getAllFieldsFromDocSnapshot(curApplicationSnap);
  const country = getCountry(countriesData.countries, curApplication.country_code); // TODO: можно скачать коллекцию с одной страной, а не всеми.
  const dialogueSnap = chatCollSnapshot.docs[0];
  const curAppStatus = curApplication.preparedInformation.preparationStatus;
  const accesses = checkAccesses(authorizedUser, curApplication, allClientAppsData)
  const dialogue = dialogueSnap.data();

  return (
    <ApplicationStatus.Provider value={{curAppStatus, appId, accesses, assignedOperator, assignedOperatorError}}>
      <Layout style={{height:"calc(100vh - 64px)", padding:"0 10px 0"}}>
        <Row gutter={20} style={{height:"100% "}}>
          <Col className='application-workpage__left' span={12}>
            <ApplicationToolBar
              clientId={clientId}
              curApplication={curApplication}
              country={country}
              appRef={curApplicationSnap.ref}
              dialogueSnap={dialogueSnap}
              currentClientApplications={allClientAppsCollSnapshot.docs}
            />
            <ApplicationAdditionals curApplicationSnap={curApplicationSnap} applicantsNumber={curApplication.passports.length}/>
            <div style={{ color:"#0F6CA5"}}>
              <ApplicantsPassports passports={curApplication.passports} appRef={curApplicationSnap.ref}/>
              <ApplicationQuestionnaires questionnaire={curApplication.questionnary?.answers} appRef={curApplicationSnap.ref} passports={curApplication.passports}/>
            </div>
          </Col>
          <Col span={12} className='application-workpage__right'>
            {!accesses.chatIsNotDisplayed ? (
              <div className="chat-section">
                <ChatDataProvider
                  UID={clientId}
                  selectedChatSnapId={dialogueSnap.id}
                >
                  <Chat
                    applicantId={clientId}
                    clientData={clientData}
                    source="application"
                    dialogue={dialogue}
                    selectedChatSnapId={dialogueSnap.id}
                  />
                </ChatDataProvider>
              </div>
            ) : (
              null
            )}
            <div className='sections-divider'></div>
            <TeamApplicationChat appId={appId} teamDocs={curApplication.teamDocs}/>
            <Documents
              uploadedDocs={curApplication.preparedInformation.documents}
              curAppDocumentId={curApplication.documentID}
              passports={curApplication.passports}
              countryCode={curApplication.country_code}
              servicePrice={curApplication.service.price_USD}
              citizenship={curApplication.citizenship}
              assistantsData={assistantsData}
            />
          </Col>
        </Row>
      </Layout>
    </ApplicationStatus.Provider>
  )
}

export default ApplicationWorkPage;
